// Note: Requires flexslider 2
jQuery(document).ready(function($) {

	/********************************
	 ANIMATED CRAB
	*********************************/

	if ( $('.gcom-animated-logo').length ) {
		console.log('starting animation');
		$(function() {
			setInterval(function() {
				$('.gcom-animated-logo').animate({ left: $(window).width() + 'px' }, 12000, 'linear', function() {
					$(this).css({ left: - $(this).width() + 'px' });
				});
			}, 10);
		});
	}

	/********************************
	 SWAP TABS ON RESTAURANT PAGE
	*********************************/

	
	// Flexslider
	$('.menu-slides .flexslider').flexslider({
		animation: "slide",
		prevText: "",
		nextText: "",
		controlNav: true,
		directionNav: false,
	});

	//if ($('body.page-template-page-menu').length > 0) { // Only run this code if we are using the correct page template.
		// console.log('Page menu template has been loaded.');
		if(window.location.hash) {
			// We only arrive here if the URL has a # in it.
			// Strip the # from the URL hash and assign it to a variable. This is the name of the side tab requested.
			var requestedMenu = window.location.hash.substring(1, window.location.hash.length);
			// Get the element whose data-tab-slug attribute equals the one passed in via the hash
			$target_menu = $(".menu-nav-wrapper [data-tab-slug*=" + requestedMenu + "]");
			var target_menu_id = $target_menu.attr('data-tab');
			// console.log('Targeted tab id=' + target_menu_id);
			// Make them all transparent
			$('.menu-nav-wrapper .tab-link').addClass('btn-transparent');
			// Remove the current class from all content and make them hidden
			$('.tab-content').removeClass('current');
			// $('.tab-content').css('display', 'none');

			// For the current button, add the primary button class
			$target_menu.removeClass('btn-transparent');
			// For the clicked tab, add the current class and show it.
			$("#"+target_menu_id).addClass('current');

			// Now we handle the mobile menu, where we have to do many of the same things. A smarter dev would figure out how to do this all in one batch, but you go to war with the dev you've got. 
			$('.menu-nav-select').text(requestedMenu);
			$(".menu-nav-mobile-menus-container .tab-link[data-tab-slug*=" + requestedMenu + "]").removeClass('fg-black');
			$(".menu-nav-mobile-menus-container .tab-link[data-tab-slug*=" + requestedMenu + "]").addClass('fg-c1');

		}
	//}

	$toggleButtons = $('.menu-nav-wrapper .tab-link');

	$toggleButtons.click(function( event ){
		// console.log('clicked');
		event.preventDefault();
		var tab_id = $(this).attr('data-tab');
		var tab_slug = $(this).attr('data-tab-slug');
		var tab_number = tab_id.split("-").pop();
		// change the url in the address bar to show which tab is current
		window.history.pushState("object or string", "Title", "#" + tab_slug);
		// Make them all transparent
		$('.menu-nav-wrapper .tab-link').addClass('btn-transparent');
		// Remove the current class from all content and make them hidden
		$('.tab-content').removeClass('current');
		// $('.tab-content').css('display', 'none');

		// For the current item, change to button primary
		$(this).removeClass('btn-transparent');
		// $(this).addClass('btn-primary');
		// For the clicked tab, add the current class and show it.
		$("#"+tab_id).addClass('current');
		// $("#"+tab_id).css('display', 'block');
	})

	// MOBILE MENU HANDLING

	$('.menu-nav-wrapper-mobile').unbind().click(function( event ){
		$(this).children('.menu-nav-mobile-menus-container').toggleClass('open');
	})

	$('.menu-nav-mobile-menus-container .tab-link').click(function( event ){
		console.log('clicked');
		event.preventDefault();
		var tab_id = $(this).attr('data-tab');
		var tab_slug = $(this).attr('data-tab-slug');
		var tab_number = tab_id.split("-").pop();
		// change the url in the address bar to show which tab is current
		window.history.pushState("object or string", "Title", "#" + tab_slug);
		// Strip away the color fg & add black back to them all, resetting to default basically.
		$('.menu-nav-mobile-menus-container .tab-link').removeClass('fg-c1');
		$('.menu-nav-mobile-menus-container .tab-link').addClass('fg-black');
		// Remove the current class from all content and make them hidden
		$('.tab-content').removeClass('current');
		// Add the active color to the clicked item
		$(this).addClass('fg-c1');
		$(this).removeClass('fg-black');
		// For the clicked tab, add the current class and show it.
		$("#"+tab_id).addClass('current');
		// Change the text on the "select box" to match the clicked item
		$('.menu-nav-select').text($(this).text());
	});

}); /* end of as page load scripts */